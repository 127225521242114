import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';
import { Service } from 'utils';

const defaultParams = {
  products: [],
  totalCount: 0,
};

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  keepUnusedDataFor: 60 * 60 * 1, // 資料保留1小時
  endpoints: (builder) => ({
    categoryRangeByIds: builder.query({
      queryFn: async (reqData) => {
        const { start, end, ids } = reqData;
        const resData = await Service.categoryRangeQuery(reqData);
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有相關分類',
          };
        }

        const { totalCount, products } = data;

        return {
          data: {
            products,
            totalCount,
            start,
            end,
            ids,
          },
        };
      },

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          // 切換頁籤不做任何暫存
          if (newItems.ids !== currentCache.ids) {
            return newItems;
          }
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const useCategoryRangeState = categoryApi.endpoints.categoryRangeByIds.useQueryState;
export const { useLazyCategoryRangeByIdsQuery } = categoryApi;
export const resetCategory = () => categoryApi.util.resetApiState();

// 無限下拉記憶用
const initialState = {
  scrollMemory: {
    ids: '',
    position: { start: null, end: null },
    visit: 0,
    memory: 0
  },
};
const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetCategoryScroll: () => initialState,
    setCategoryScroll: (state, { payload }) => {
      state.scrollMemory = payload;
    },
  },
});

export const { resetCategoryScroll, setCategoryScroll } = categorySlice.actions;
export default categorySlice.reducer;
