import { useCallback } from 'react';

import { constants, dayjs, checkIsIphone } from 'utils';

const { infiniteScrollConfig } = constants;

export const useScrollRange = ({
  range,
  setRange,
  setVisibleRange,
  visibleRange,
  fetchOptions,
  fetchItems,
  resetItems,
  isFetching,
  data,
  platform,
  setScroll,
  memoryOptions,
}) => {
  /**
   * 初始化載入資料
   * @param {*} props: 各頁面自訂的紀錄資訊, 例如分類ID
   */
  const initLoadData = (props) => {
    resetItems();
    fetchItems({
      ...fetchOptions,
      ...props,
      start: 1,
      end: infiniteScrollConfig.range[platform],
    });
    setVisibleRange({
      startIndex: 0,
      endIndex: 0,
    });
    setRange({
      start: 1,
      end: infiniteScrollConfig.range[platform],
    });
    setScroll({
      ...memoryOptions,
      position: {
        start: 1,
        end: infiniteScrollConfig.range[platform],
      },
      memory: 0,
      visit: dayjs().valueOf(),
    });
  };

  // 動態載入資料
  const fetchData = (start, end) => {
    fetchItems({
      ...fetchOptions,
      start,
      end,
    });
    setRange({
      start,
      end,
    });
  };

  // 下拉載入
  const loadMore = useCallback(() => {
    return setTimeout(() => {
      if (!isFetching && range.end < data?.totalCount) {
        let newStart = range.end + 1;
        let newEnd = range.end + infiniteScrollConfig.range[platform];
        if (newEnd > data?.totalCount) {
          newEnd = data.totalCount;
        }
        if (newStart >= newEnd) {
          return;
        }
        fetchData(newStart, newEnd);
      }
    }, 1000);
  }, [range, setRange, isFetching]);

  // 記憶下次載入資料範圍與瀏覽位置
  const memoryFunc = useCallback(() => {
    if (visibleRange.endIndex < 1) {
      return;
    }
    const { start, end } = range;
    let memory = visibleRange.endIndex;

    // 使用者裝置確認: iphone 或其他
    if (checkIsIphone()) {
      memory -= 2;
    } else {
      memory -= 4;
    }

    /**
     * 位置校正結束後的再確認, 對應iphone的跑版現象
     *  - 裝置為iphone
     *  - 起始位置被4整除後剩下2或0, 剛好位於畫面交界處
     *  - 校正後的memory大於起始位置
     */
    if (
      checkIsIphone() &&
      (visibleRange.startIndex % 4 === 2 ||
        visibleRange.startIndex % 4 === 0) &&
      memory > visibleRange.startIndex
    ) {
      memory -= 2;
    }

    // 避免出現負數情況
    if (memory < 0) {
      memory = 0;
    }

    setScroll({
      ...memoryOptions,
      position: {
        start,
        end,
      },
      memory,
      visit: dayjs().valueOf(),
    });
  }, [range, setRange, visibleRange]);

  return { initLoadData, fetchData, loadMore, memoryFunc };
};
